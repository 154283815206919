import React, { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie-player";
import { cn } from "../lib/helpers";
import styles from "./lottie-section.module.css";
import PropTypes from "prop-types";
import useOnScreen from "../lib/useOnScreen";

const LottieSection = (props) => {
  const { lottieFile, loop, disablePadding } = props;
  const [animationData, setAnimationData] = useState(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const defaultOptions = {
    loop: loop,
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(lottieFile.asset.url);
      let responseJson = await response.json();

      setAnimationData(responseJson);
    };
    fetchData();
  }, []);

  return (
    <div className={cn(styles.root, disablePadding ? styles.disablePadding : "")} ref={ref}>
      {animationData && (
        <Lottie
          {...defaultOptions}
          play={isVisible && (loop || !animationComplete)}
          animationData={animationData}
          onComplete={() => setAnimationComplete(true)}
        />
      )}
    </div>
  );
};

LottieSection.propTypes = {
  lottieFile: PropTypes.object,
  loop: PropTypes.bool,
};

export default LottieSection;
