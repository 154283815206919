// check-if-element-is-visible-in-dom using Intersection Observer API

import { useState, useEffect } from "react";

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer =
    typeof window !== `undefined`
      ? new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
      : null;

  useEffect(() => {
    if (observer) {
      observer.observe(ref.current);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return isIntersecting;
}
